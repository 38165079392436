import React from 'react';
import { FaCheck } from 'react-icons/fa';

const InsuranceDetails = ({ insurance }) => {
    return (
      <div className="bg-gray-100 p-6 rounded-lg">
        <h2 className="text-xl font-semibold mb-4">Ihre Auswahl</h2>
       
        <div className="mb-6">
        {insurance.logo ? (
  <img 
    src={`${process.env.REACT_APP_API_BASEURL.replace('/api', '')}${insurance.logo}`} 
    alt={`${insurance.companyName} logo`} 
    className="max-h-full max-w-full object-contain"
    onError={(e) => {
      console.error("Error loading image:", e);
      e.target.onerror = null; 
      e.target.src = "/path/to/fallback/image.png";
    }}
  />
) : (
  <div className="text-lg font-semibold text-center text-gray-700">
    {insurance.companyName}
  </div>
)}
        </div>



      <div className="mb-6">
        <h3 className="text-lg font-semibold">Neuer Monatlicher Beitrag</h3>
        <p className="text-4xl font-bold">{insurance.monthlyCost.toString().replace(".", ",")} €</p>
        <p className="text-green-600">{insurance.savings.toString().replace(".", ",")} € pro Jahr sparen</p>
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-2">DentNet inklusive</h3>
        <ul className="space-y-2">
          <li className="flex items-center">
            <FaCheck className="text-green-500 mr-2" />
            <span>Jährliche Professionelle Zahnreinigung</span>
          </li>
          <li className="flex items-center">
            <FaCheck className="text-green-500 mr-2" />
            <span>Zahnreinigung für Schwangere</span>
          </li>
          <li className="flex items-center">
            <FaCheck className="text-green-500 mr-2" />
            <span>Zahnersatz mit fünf Jahren Garantie</span>
          </li>
          <li className="flex items-center">
            <FaCheck className="text-green-500 mr-2" />
            <span>Zahnkorrektur mit Clear Alignern</span>
          </li>
          <li className="flex items-center">
            <FaCheck className="text-green-500 mr-2" />
            <span>1.250 € Preisgarantie pro Implantat</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InsuranceDetails;