import React, { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import ContactPageBasicUI from "./application/ApplicationPageBasicUI";
import FORM_DATA from "./application/ContactFormBuilder.json";
import InsuranceDetails from "../components/InsuranceDetails";
import { useNavigate } from "react-router-dom";
import { getAllInsurance, getAllApplication, saveContactForms } from "../Apis";
import { convertYesNoToBool } from "../utils/utils";

const ApplicationPage = () => {
  const [searchParams] = useSearchParams();
  const [formData] = useState(FORM_DATA);
  const [isOpen, setIsOpen] = useState(0);
  const [groupForm, setGroupForm] = useState({});
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [insuranceRemData, setInsuranceRemData] = useState({});
  const [userParsedData, setUserParsedData] = useState(null);
  const [isInsuranceDetailsOpen, setIsInsuranceDetailsOpen] = useState(false);
  const { insuranceId, employmentStatus } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const data = searchParams.get("data");
    if (data) {
      const convertedData = JSON.parse(atob(data));
      setUserParsedData(convertedData);
      setSelectedInsurance(convertedData.selectedInsurance);
    }
    mainLoader();
  }, [location]);

  const mainLoader = async () => {
    try {
      const remData = await getAllInsurance();
      setInsuranceRemData(remData?.insuranceCompanies);
      const apps = await getAllApplication();
      console.log(apps);
    } catch (e) {
      console.log(e);
    }
  };

  const submitFormData = async (e) => {
    e.preventDefault();
    const cleanData = convertYesNoToBool(groupForm);
    const networkData = {
      application: { ...cleanData, health_insurance: selectedInsurance._id },
      processed: false,
      companyName: userParsedData.currentInsurance?.companyName,
    };
    const submitFormApi = await saveContactForms(networkData);
    navigate("/success");
    console.log(submitFormApi);
  };

  const boolIsEmployed = employmentStatus === "2";

  return (
    <div className="container mx-auto px-4 py-8 max-w-6xl">
      <h1 className="text-3xl md:text-4xl font-light text-center mb-8 text-gray-700">
        Jetzt Mitgliedschaft bei der{" "}
        {selectedInsurance?.companyName || "Krankenkasse"} beantragen
      </h1>

    {/* Insurance Details for mobile and tablet */}
    <div className="lg:hidden mb-8">
        {selectedInsurance && (
          <div className="bg-gray-100 rounded-lg p-4">
            <button
              onClick={() => setIsInsuranceDetailsOpen(!isInsuranceDetailsOpen)}
              className="w-full text-left font-semibold text-lg mb-2"
            >
              Ihre Auswahl {isInsuranceDetailsOpen ? '▲' : '▼'}
            </button>
            {isInsuranceDetailsOpen && <InsuranceDetails insurance={selectedInsurance} />}
          </div>
        )}
      </div>


      <div className="flex flex-col lg:flex-row gap-8">
        <div className="w-full lg:w-2/3">
          <form onSubmit={submitFormData} className="space-y-4">
            {formData.map(
              (d, i) =>
                d.allowed && (
                  <ContactPageBasicUI
                    key={i}
                    isVisible={boolIsEmployed}
                    extraData={userParsedData}
                    userData={insuranceRemData}
                    isOpen={isOpen}
                    title={d.title}
                    data={d}
                    itemPos={i}
                    onSave={(d) => {
                      setGroupForm({ ...groupForm, ...d.data });
                      setIsOpen((i + 1) % formData.length);
                    }}
                  />
                )
            )}
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded w-full mt-6 transition duration-300"
            >
              Antrag Senden
            </button>
          </form>
        </div>

      {/* Insurance Details for desktop */}
      <div className="hidden lg:block w-full lg:w-1/3">
        {selectedInsurance && (
          <div className="bg-gray-100 rounded-lg p-6 sticky top-8">
            <InsuranceDetails insurance={selectedInsurance} />
          </div>
        )}
      </div>
      </div>
    </div>
  );
};

export default ApplicationPage;
