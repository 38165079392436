export const getTooltipText = (type) => {
  const typeMap = {
    toothCorrection: "test",
    pzr: "test",
    fluVaccine: "test",
    travelVaccine: "test",
    healthCourses: "test",
    onlineCourses: "test",
    fitnessTracker: "test",
    acupuncture: "test",
    homeopathicMedicine: "test",
    skinCancer: "test",
    colorectalCancer: "test",
    breastCancer: "test",
    artificialInsemination: "test",
    childrenInsepection: "test",
    midWifeServices: "test",
    osteopathy: "test",
    homeopathy: "test",
  };
  return typeMap[type] || type;
};