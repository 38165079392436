export const getQuestionTypeName = (type) => {
    const typeMap = {
      dentures: "Vergünstigter Zahnersatz",
      toothCorrection: "Vergünstigte Zahnkorrektur",
      pzr: "Professionelle Zahnreinigung",
      fluVaccine: "Grippeschutzimpfung",
      travelVaccine: "Reiseschutzimpfung",
      healthCourses: "Gesundheitskurse",
      onlineCourses: "Online-Kurse",
      fitnessTracker: "Fitness-Tracker",
      acupuncture: "Akupunktur",
      homeopathicMedicine: "Homöopath. Medikamente",
      skinCancer: "Hautkrebsvorsorge",
      colorectalCancer: "Darmkrebsvorsorge",
      breastCancer: "Brustkrebsvorsorge",
      artificialInsemination: "Künstliche Befruchtung",
      childrenInsepection: "Kinder-Gesundheitscheck",
      midWifeServices: "Hebammenrufbereitschaft",
      osteopathy: "Osteopath. Behandlung",
      homeopathy: "Homöopath. Behandlung",
    };
    return typeMap[type] || type;
  };