import React, { useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { RiCheckboxCircleLine } from "react-icons/ri";
import { Tooltip } from "react-tooltip";
import { getTooltipText } from "../utils/tooltips";
import { getQuestionTypeName } from "../utils/questionTypeTranslator";
import ToolTipContent from "./ui/toolTipContent";

const CurrentInsuranceCard = ({ insurance }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  const getSectionTitle = (title) => {
    const titleMap = {
      teeth: "Zähne",
      vaccine: "Impfungen",
      sports: "Sport & Bewegung",
      medicine: "Medizin",
      cancer: "Krebsvorsorge",
      artificial: "Künstliche Befruchtung",
      service: "Service",
    };
    return titleMap[title] || title.charAt(0).toUpperCase() + title.slice(1);
  };

  const getSectionRating = (questions) => {
    const totalQuestions = questions.length;
    const availableQuestions = questions.filter(
      (q) => q.value === "yes"
    ).length;
    const missingQuestions = totalQuestions - availableQuestions;

    if (totalQuestions === 0) return "";
    if (missingQuestions === 0) return "sehr gut";
    if (missingQuestions === 1 && totalQuestions > 1) return "gut";
    return "";
  };

  return (
    <div className="rounded-lg border border-gray-200 bg-blue-50 mb-6 relative overflow-hidden">
      <div className="absolute top-0 right-0 md:right-5 bg-[#00508b] text-white px-2 md:px-4 py-1 md:py-2 text-xs md:text-sm font-semibold z-10">
        Ihre Krankenkasse
      </div>
      <div className="p-4 md:p-6">
        <div className="flex flex-col md:flex-row items-start">
          <div className="w-full md:w-1/4 pr-0 md:pr-4 mb-4 md:mb-0">
            <div className="w-full h-16 md:h-24 flex flex-col gridViewUIs">
              {insurance.logo ? (
                <img
                  src={`${process.env.REACT_APP_API_BASEURL.replace(
                    "/api",
                    ""
                  )}${insurance.logo}`}
                  alt={`${insurance.companyName} logo`}
                  className="max-h-full max-w-full object-contain topImage"
                  onError={(e) => {
                    console.error("Error loading image:", e);
                    e.target.onerror = null;
                    e.target.src = "/path/to/fallback/image.png";
                  }}
                />
              ) : (
                <div className="text-lg font-semibold text-center text-gray-700">
                  {insurance.companyName}
                </div>
              )}
            </div>
          </div>

          <div className="w-full md:w-1/2 px-0 md:px-4 mb-4 md:mb-0">
            <p className="text-sm md:text-base text-gray-600 mb-2">
              Beitragssatz: {(insurance.additionalContribution + 14.6).toString().replace(".", ",")}%
            </p>
            <div className="grid grid-cols-2 gap-2 md:block">
              {insurance.sections &&
                insurance.sections.map((section, index) => (
                  <p
                    key={index}
                    className="flex items-center text-xs md:text-sm mb-1"
                    style={{
                      display:
                        getSectionRating(section.questions) === "" && "none",
                    }}
                  >
                    <span className="text-green-500 mr-1 md:mr-2">
                      <RiCheckboxCircleLine size={16} />
                    </span>
                    <span className="font-semibold mr-1">
                      {getSectionTitle(section.title)}:
                    </span>
                    <span className="text-gray-700">
                      {getSectionRating(section.questions)}
                    </span>
                  </p>
                ))}
            </div>
          </div>

          <div className="w-full md:w-1/4 text-left md:text-right md:pt-8">
            <p className="text-xl md:text-2xl font-bold">
              {insurance.monthlyCost.toString().replace(".",",")} €
            </p>
            <p className="text-xs md:text-sm text-gray-500">
              monatlich
              <span
                data-tooltip-id="tooltip-price-explanation"
                data-tooltip-content={insurance.additionalContribution}
                className="cursor-help inline-block ml-1 w-4 h-4 text-xs bg-gray-300 rounded-full text-center text-gray-600"
              >
                ?
              </span>
              <Tooltip
                id="tooltip-price-explanation"
                style={{ backgroundColor: "white" }}
                render={({ content }) => (
                 <ToolTipContent content={content}/>
                )}
              />
            </p>
          </div>
        </div>

        <div className="flex justify-between items-center mt-4 pt-4 border-t border-gray-200">
          <button
            className="text-sm text-gray-600 hover:text-blue-800 focus:outline-none"
            onClick={toggleOpen}
          >
            Detaillierte Leistungsübersicht {isOpen ? "▲" : "▼"}
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="border-t border-gray-200 p-4 md:p-6">
          {insurance.sections &&
            insurance.sections.map((section, index) => (
              <div key={index} className="mb-6">
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center bg-gray-100 p-3 rounded-t-lg">
                  <h3 className="font-semibold text-gray-700 w-full md:w-1/3 mb-2 md:mb-0">
                    {getSectionTitle(section.title)}
                  </h3>
                  <span
                    className={`w-full md:w-1/3 text-left md:text-center ${
                      getSectionRating(section.questions)
                        ? "text-green-500"
                        : ""
                    }`}
                  >
                    {getSectionRating(section.questions)}
                  </span>
                  <div className="w-full md:w-1/3"></div>
                </div>
                <div className="bg-white rounded-b-lg shadow-sm">
                  {section.questions.map((question, qIndex) => (
                    <div
                      key={qIndex}
                      className="flex flex-col md:flex-row justify-between items-start md:items-center py-3 px-4 border-b border-gray-100 last:border-b-0"
                      style={{ display: question.value === "no" && "none" }}
                    >
                      <span className="text-gray-700 text-sm w-full md:w-1/3 mb-2 md:mb-0">
                        {getQuestionTypeName(question.id.split("_")[0])}
                      </span>
                      <div className="flex items-center justify-start md:justify-center w-full md:w-1/3">
                        {question.value === "yes" ? (
                          <>
                            <FaCheck className="text-green-500 mr-2" />
                            <span className="text-gray-600 text-sm">
                              Wird übernommen
                            </span>
                          </>
                        ) : (
                          <>
                            <FaTimes className="text-red-500 mr-2" />
                            <span className="text-gray-600 text-sm">
                              Nicht übernommen
                            </span>
                          </>
                        )}
                      </div>
                      <div className="w-full sm:w-1/3 flex justify-start sm:justify-end">
                        <span
                          data-tooltip-content={getTooltipText(
                            question.id.split("_")[0]
                          )}
                          data-tooltip-id="tooltip-benefit"
                          className="cursor-help inline-block ml-1 w-4 h-4 text-xs bg-gray-300 rounded-full text-center text-gray-600"
                        >
                          ?
                        </span>
                        <Tooltip
                          id="tooltip-benefit"
                          place="top"
                          effect="solid"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default CurrentInsuranceCard;
