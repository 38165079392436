import React, { useEffect, useState } from "react";
import { FaInfoCircle, FaArrowRight } from "react-icons/fa";

import {
  filterInsuranceByRegion,
  insuranceCalculator,
  postalResolver,
} from "../utils/helpers";
import { getAllInsurance } from "../Apis";

function PreinformationPage() {
  //variables declarations
  const [insuranceRemData, setInsuranceRemData] = useState([]);
  const [regionalInsurance, setRegionalInsurance] = useState([]);
  const [insuranceObjData, setInsuranceObjData] = useState(null);
  const [insuranceIndex, setInsuranceIndex] = useState(0);
  const [employedText, setEmployedText] = useState(0);
  const [zipCodeText, setZipCodeText] = useState("");
  const [salaryText, setSalaryText] = useState(0);
  const [currentInsuranceName, setCurrentInsuranceName] = useState("");
  const [cityName, setCityText] = useState("");
  const [calculatedMin, setCalculatedMin] = useState(0);
  const [calculatedDiff, setCalculatedDiff] = useState(0);
  //main thread...
  /**
   * use effect make more sense if used
   */

  //put all initializer here
  const mainLoader = async () => {
    try {
      const remData = await getAllInsurance();
      setInsuranceRemData(remData?.insuranceCompanies);
      setInsuranceObjData(remData?.insuranceCompanies[0]);
    } catch (e) {
      console.log(e);
    }
  };

  //Get list of insurances
  useEffect(() => {
    mainLoader().then(null);
  }, []);

  //React on items changes
  useEffect(() => {
    if (cityName.length > 0 && insuranceObjData) {
      //run auto calculations
      __performCalculations();
    }
  }, [cityName, insuranceObjData, salaryText, zipCodeText, employedText]);

  //Listing to zipCode on Change
  useEffect(() => {
    //update city name from zip code
    const __cityName = postalResolver(zipCodeText);
    setCityText(zipCodeText.length > 2 ? __cityName : "");
    //if set then sort insurance
    if (zipCodeText.length > 3) __getOperatingRegions();
  }, [zipCodeText]);
  /**
   * Comparison functions caller
   */
  const __getOperatingRegions = () => {
    //sortingAlgorithms
    let __SortedInsurance = filterInsuranceByRegion(insuranceRemData, cityName);
    setRegionalInsurance(__SortedInsurance);
    //run comparison
    //__performCalculations()
    //console.log("Operating Insurance", __SortedInsurance)
  };
  //apply calculations and determine
  const __performCalculations = () => {
    //form data
    const data = {
      salary: salaryText,
      employment: employedText,
      data: regionalInsurance, //either user data in array or whole array
    };
    const retResult = insuranceCalculator(data).sort();
    //return calculation array
    __calculateForDifference(retResult);
  };
  //calculate for interest
  const __calculateForDifference = (data) => {
    const userInsurance = {
      salary: salaryText,
      employment: employedText,
      data: [insuranceObjData], //either user data in array or whole array
    };
    const theCheapest = data[0];
    //run for difference
    const retResultSingle = insuranceCalculator(userInsurance, false);
    const singleDiff = Number(retResultSingle[0]) - Number(theCheapest);
    //Monthly savings
    setCalculatedMin(Number(theCheapest / 12));
    setCalculatedDiff(Number(singleDiff).toFixed(2));
  };
  //Auto determine insurance savings on inputs
  const __calculateOnInput = (e) => {
    //get amount from amount
    const v = Number(e.target.value);
    if (cityName.length > 0 && v > 0 && insuranceObjData) {
      //run auto calculations
      __performCalculations();
    } else {
      setCalculatedMin(0);
      setCalculatedDiff(0);
    }
  };

  //filter and process
  const btnCompareNext = () => {
    //check nullable
    if (!insuranceObjData || !zipCodeText || !salaryText) {
      alert("Carefully fill the form and try again");
      return;
      //nothing to do at this point
    }
    //call function
    __getOperatingRegions();
    //take me to next stage
    const nextUrlParams = btoa(
      JSON.stringify({
        salary: salaryText,
        cityName: cityName,
        zipCode: zipCodeText,
        employmentStatus: employedText,
        insuranceIndex: insuranceIndex
      })
    );
    window.location.href = `/comparison-platform?data=${nextUrlParams}`;
  };
  return (
    <>
      <div className={"container"}>
        <div className="p-10 center">
          <div className="p-4 bg-[#FEF7FA] w-full">
            <div className="grid mb-4">
              <h1 className="text-[20px] text-[#8D1558] mb-4 font-miloMedium">
                Beiträge vergleichen und Kasse wechseln
              </h1>
              <div className="grid md:grid-cols-2 gap-4">
                <div className="grid">
                  <label
                    htmlFor=""
                    className="text-[16px] text-[#8D1558] font-[400] mb-1 font-miloMedium"
                  >
                    Aktuelle Krankenkasse
                  </label>
                  <select
                    name="x1"
                    id="x1"
                    onChange={(s) => {
                      setInsuranceObjData(insuranceRemData[s.target.value]);
                      setInsuranceIndex(Number(s.target.value));
                      setCurrentInsuranceName(
                        insuranceRemData[Number(s.target.value)]
                      );
                    }}
                    value={insuranceIndex}
                  >
                    <option value={""} disabled={true}>
                      --Select Insurance---
                    </option>
                    {insuranceRemData?.map((i, k) => (
                      <option key={k} value={k}>
                        {i?.companyName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="grid">
                  <label
                    htmlFor=""
                    className="text-[16px] text-[#8D1558] font-[400] mb-1 font-miloMedium"
                  >
                    Aktuelle Versicherungsart
                  </label>
                  <select name="" id="" disabled={true}>
                    <option value="">Gesetzlich krankenversichert</option>
                  </select>
                </div>
                <div className="grid">
                  <label
                    htmlFor=""
                    className="text-[16px] text-[#8D1558] font-[400] mb-1 font-miloMedium"
                  >
                    Berufsgruppe
                  </label>
                  <select
                    name="x2"
                    onChange={(e) => setEmployedText(Number(e.target.value))}
                  >
                    <option disabled={true} value={""}>
                      --Select Employment Status--
                    </option>
                    <option value={1}>Angestellt</option>
                    <option value={2}>Selbstständig</option>
                  </select>
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <div className="grid">
                    <label
                      htmlFor=""
                      className="text-[16px] text-[#8D1558] font-[400] mb-1 font-miloMedium"
                    >
                      Ihre Postleitzahl: {cityName}
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setZipCodeText(e.target.value)}
                    />
                  </div>
                  <div className="grid">
                    <label
                      htmlFor=""
                      className="text-[16px] text-[#8D1558] font-[400] mb-1 font-miloMedium"
                    >
                      Brutto Jahresgehalt Euro
                    </label>
                    <input
                      onBlur={(e) => __calculateOnInput(e)}
                      onMouseLeave={(e) => __calculateOnInput(e)}
                      type="number"
                      onChange={(e) => {
                        setSalaryText(Number(e.target.value));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {salaryText != 0 && zipCodeText != "" && (
              <>
                <hr className="border-t-[#f6bfd8] border-wine-400" />{" "}
                <div className="py-5 flex items-center  px-1 gap-3">
                  <FaInfoCircle className="text-[#8D1558] text-[25px]" />
                  <div className="grid">
                    <h3 className="text-[20px] text-[#8D1558] font-[400] mb-1 font-[MiloMedium]">
                      Vergleichsergebnis:
                    </h3>
                    <span className="flex gap-2 items-center justify-start">
                      <p className="text-[14px] text-[#8D1558] font-[200] mb-1">
                        Neuer Beitrag monatlich{" "}
                        {Number(calculatedMin).toFixed(2)} €
                      </p>
                      <FaArrowRight className="text-[#8D1558] text-[14px]" />
                    </span>
                  </div>
                  <p className="text-[14px] text-green-600 font-[400] mt-5">
                    Ersparnis jährlich ca. {Number(calculatedDiff).toFixed(2)} €
                  </p>
                </div>
              </>
            )}
            <hr className="border-t-[#f6bfd8] border-wine-400 mb-4" />

            <button
              onClick={btnCompareNext}
              className="bg-[#8D1558] p-2 w-full text-white rounded font-[MiloMedium]"
            >
              Krankenkasse wechseln
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PreinformationPage;
