import { useEffect, useState } from "react";

const ApplicationPageBasicUI = ({
  data,
  onSave,
  title,
  isOpen,
  itemPos,
  extraData,
  isVisible,
}) => {
  //set title

  //switch for cases
  const [isEditable, setIsEditable] = useState(false);
  const [isAutoNext, setIsAutoNext] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [tmpDataPrev, setTmpDataPrev] = useState(data);
  const [tmpData, setTmpData] = useState({});
  const [tmpDataCache, setTmpDataCache] = useState({});
  const [userInsurance, setUserInsurance] = useState(null);
  const [employmentVisibility, setEmploymentVisibility] = useState(0);

  useEffect(() => {
    //console.warn(extraData?.currentInsurance)
    //prefilled data from extraData
    if (data.key === "current_health_insurance_info") {
      console.log(extraData);
      setTmpData({
        current_health_insurance:
          extraData?.currentInsurance?.companyName || "Nicht ausgewählt",
      });
    }
  }, [extraData]);
  document.title = "Antrag" + extraData?.currentInsurance?.companyName && extraData?.currentInsurance?.companyName;
  useEffect(() => {
    if (isOpen === itemPos) {
      setIsExpanded(true);
      setIsEditable(true);
    }
    if (isAutoNext) {
      setIsEditable(false);
    }
    if (data.key === "additional_info" || data.key === "employer_info") {
      setEmploymentVisibility(1);
    }
  }, [isOpen]);

  const onTypeEffect = (e, d) => {
    setTmpData({ ...tmpData, [d.key]: e.target.value });
  };

  //null check
  const btnAddData = () => {
    //check null state and callback
    onSave({ data: { [data.key]: tmpData }, currentIndex: isOpen });
    setTmpDataPrev({ ...data });
    setIsAutoNext(true);
  };

  if (employmentVisibility === 1) {
    if (data.isEmployed !== isVisible) {
      return null;
    }
  }

  //boolean resolver
  const _getBoolean = (str) => {
    if (str.toLowerCase() === "yes") return true;
    if (str.toLowerCase() === "no") return false;
    else return str;
  };
  return (
    <>
      <div className={"mb-4 divShadow"} key={"samples-keys"}>
        <div
          className={"flex flex-col w-full"}
          style={{ borderColor: "#d0d0d0", borderWidth: 1, padding: 20 }}
        >
          <div className="flex flex-col w-full" style={{ marginBottom: 5 }}>
            <div className="flex flex-row w-full space-x-1">
              <p
                onClick={() => {
                  //expand card
                  setIsExpanded(!isExpanded);
                }}
                style={{ color: "#00508b", cursor: "pointer" }}
                className="text-black text-[22px] w-full"
              >
                {title}
              </p>

              <p
                className="pull-right edit-buttonEffect"
                style={{ cursor: "pointer", display: isExpanded ? "" : "none" }}
                onClick={() => {
                  //click to toggle edit mode
                  setIsEditable(!isEditable);
                }}
              >
                <span style={{ float: "right" }}>
                  <img src={"/pencil.png"} alt={""} />
                </span>
              </p>
            </div>
          </div>

          <div
            style={{ display: isExpanded ? "" : "none" }}
            key={"main-container"}
          >
            <div
              style={{ display: !isEditable ? "" : "none" }}
              key={"main-container-2"}
            >
              <div className="flex flex-col w-full">
                {tmpDataPrev.fields.map((item, index) => (
                    <>
                      <div
                        className="flex flex-row w-full space-x-1 my-1"
                        key={"j-" + index}
                      >
                        <h3 className="text-black text-[13px] font-bold w-full">
                          {item.title}
                          <br/>
                          {item?.links?(
                              <>
                                <a style={{fontSize: 10, color: 'green'}} target={'_blank'} href={item?.links[0]?.url}>{item?.links[0]?.title}</a>
                              </>
                          ):null}
                        </h3>
                        {item.group ? (
                          <>
                            <h3
                                className="text-secondary text-[13px] font-bold w-full"
                                style={{color: "#34b655"}}
                            >
                              {item?.fields?.map(
                                  (x, i) => (tmpData[x.key] || "-") + " "
                              )}
                            </h3>
                          </>
                        ) : (
                            <>
                              <h3
                                  className="text-secondary text-[13px] font-bold w-full"
                                  style={{color: "#34b655"}}
                              >
                                {tmpData[item.fields.key] || "--"}
                              </h3>
                            </>
                        )}
                      </div>
                    </>
                ))}
              </div>
            </div>

            <div style={{ display: isEditable ? "" : "none" }}>
              <div className="space-y-5 w-full my-5">
                {data.fields.map((d, i) => (
                  <>
                    <div
                      className="flex flex-row w-full items-center justify-between"
                      key={i + 1}
                    >
                      <label
                        htmlFor="anrede"
                        className="block mb-2 text-[12px] sm:text-[14px] md:text-[16px] font-medium text-black"
                      >
                        {d.title}
                      </label>

                      <div className="flex flex-row w-[50%] items-center">
                        {d.group ? (
                          d.fields.map((x, ix) => (
                            <>
                              <div key={ix} className={["mr-1 w-[100%]"]}>
                                {x.type === "option" ? (
                                  <>
                                    <select
                                      disabled={x.disabled}
                                      defaultValue={tmpDataCache[x.key]}
                                      value={tmpData[x.key]}
                                      className="bg-white border border-gray-300 w-full text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-primary block"
                                      required
                                      onChange={(e) => {
                                        onTypeEffect(e, x);
                                      }}
                                    >
                                      <option value={""}>--Wählen--</option>
                                      {x.listItems.map((b, c) => (
                                        <option
                                          key={c}
                                          value={_getBoolean(b.title || b)}
                                        >
                                          {b.title || b}
                                        </option>
                                      ))}
                                    </select>
                                  </>
                                ) : (
                                  <>
                                    <input
                                      disabled={x.disabled}
                                      defaultValue={tmpDataCache[x.key]}
                                      value={tmpData[x.key]}
                                      type="text"
                                      placeholder={x.title}
                                      className="bg-white border border-gray-300 w-full text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-primary block"
                                      required
                                      onChange={(e) => {
                                        onTypeEffect(e, x);
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            </>
                          ))
                        ) : (
                          <>
                            <div key={"p-" + i} className={["mr-1 w-[100%]"]}>
                              {d.fields.type === "option" ? (
                                <>
                                  <select
                                    disabled={d.fields.disabled}
                                    defaultValue={tmpDataCache[d.fields.key]}
                                    value={tmpData[d.fields.key]}
                                    className="bg-white border border-gray-300 w-full text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-primary block"
                                    required
                                    onChange={(e) => {
                                      onTypeEffect(e, d.fields);
                                    }}
                                  >
                                    <option value={""}>--Wählen--</option>
                                    {d.fields.listItems.map((b, c) => (
                                      <option key={c} value={b.title || b}>
                                        {b.title || b}
                                      </option>
                                    ))}
                                  </select>
                                </>
                              ) : (
                                <>
                                  <input
                                    disabled={d.fields.disabled}
                                    defaultValue={tmpDataCache[d.fields.key]}
                                    value={tmpData[d.fields.key]}
                                    type={d.fields.type}
                                    placeholder={d.title}
                                    className="bg-white border border-gray-300 w-full text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-primary block"
                                    required
                                    onChange={(e) => {
                                      onTypeEffect(e, d.fields);
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ))}
                <button
                  onClick={() => {
                    //persist content storages
                    setTmpDataCache(tmpData);
                    btnAddData();
                  }}
                  className="bg-[#4392ec] p-2 w-full text-white rounded font-[MiloMedium]"
                >
                  Weiter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationPageBasicUI;
