import React, { useState } from "react";
import { FaCheck, FaChevronRight, FaEllipsisH } from "react-icons/fa";
import { RiCheckboxCircleLine } from "react-icons/ri";
import { Tooltip } from "react-tooltip";
import { getTooltipText } from "../utils/tooltips";
import { getQuestionTypeName } from "../utils/questionTypeTranslator";
import ToolTipContent from "./ui/toolTipContent";

const InsuranceCard = ({ insurance, isCurrentInsurance, index }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  const getSectionTitle = (title) => {
    const titleMap = {
      teeth: "Zähne",
      vaccine: "Impfungen",
      sports: "Sport & Bewegung",
      medicine: "Medizin",
      cancer: "Krebsvorsorge",
      artificial: "Künstliche Befruchtung",
      service: "Service",
    };
    return titleMap[title] || title.charAt(0).toUpperCase() + title.slice(1);
  };

  function nextToRegPage() {
    console.log("Proceed to the next page...");
    const searchParams = new URLSearchParams(window.location.search);
    const data = searchParams.get("data");
    const jData = JSON.parse(atob(data));
    const insuranceData = {
      ...jData,
      selectedInsurance: {
        companyName: insurance.companyName,
        _id: insurance._id,
        userId: insurance.userId,
        monthlyCost: insurance.monthlyCost,
        savings: insurance.savings,
        annualCost: insurance.annualCost,
        logo: insurance.logo,
      },
    };
    const encodedData = btoa(JSON.stringify(insuranceData));
    console.log(insurance)
    window.location.href = `/new-application/${insurance._id}/${jData.employmentStatus}?data=${encodedData}`;
    //window.location.href = `/new-application/${insurance._id}/${jData.employmentStatus}/${insurance.index}`;
  }

  const getSectionRating = (questions) => {
    const totalQuestions = questions.length;
    const availableQuestions = questions.filter(
      (q) => q.value === "yes"
    ).length;
    const missingQuestions = totalQuestions - availableQuestions;

    if (totalQuestions === 0) return "";
    if (missingQuestions === 0) return "sehr gut";
    if (missingQuestions === 1) return "gut";
    return "";
  };

  // console.log(
  //   "Logo URL:",
  //   `${process.env.REACT_APP_API_BASEURL}${insurance.logo}`
  // );

  return (
    <div
      className={`rounded-lg border border-gray-200 ${
        isCurrentInsurance ? "bg-blue-50" : "bg-white"
      } mb-6`}>
      {isCurrentInsurance && (
        <div className="bg-blue-700 text-white px-4 py-2 text-sm font-semibold">
          Ihre Krankenkasse
        </div>
      )}
      <div className="p-4 sm:p-6">
        <div className="flex flex-col sm:flex-row items-start">
          <div className="w-full sm:w-1/4 pr-0 sm:pr-4 mb-4 sm:mb-0">
            <div className="w-full h-24 flex flex-col gridViewUIs">
              {insurance.logo ? (
                  <img
                      src={`${process.env.REACT_APP_API_BASEURL.replace('/api', '')}${insurance.logo}`}
                      alt={`${insurance.companyName} logo`}
                      className="max-h-full object-contain topImage"
                      onError={(e) => {
                        console.error("Error loading image:", e);
                        e.target.onerror = null;
                        e.target.src = "/path/to/fallback/image.png";
                      }}
                  />
              ) : (
                  <div className="text-lg font-semibold text-center text-gray-700">
                    {insurance.companyName}
                  </div>
              )}

              <div className="w-full sm:w-1/4 text-left sm:text-right showMobilePrice moveRightDiv">
                <p className="text-2xl font-bold">{insurance.monthlyCost.toString().replace(".", ",")} €</p>
                <p className="text-xs md:text-sm text-gray-500">
                  monatlich
                  <span
                      data-tooltip-id={`tooltip-price-explanation-${insurance.id}`}
                      data-tooltip-content={insurance.additionalContribution}
                      className="cursor-help inline-block ml-1 w-4 h-4 text-xs bg-gray-300 rounded-full text-center text-gray-600"
                  >
                ?
              </span>
                  <Tooltip
                      id={`tooltip-price-explanation-${insurance.id}`}
                      style={{backgroundColor: "white"}}
                      render={({content}) => (
                          <ToolTipContent content={content}/>
                      )}
                  />
                </p>
                {!isCurrentInsurance && parseFloat(insurance.savings) !== 0 && (
                    <p
                        className={`text-sm ${
                            parseFloat(insurance.savings) > 0
                                ? "text-green-500"
                                : "text-red-500"
                        } mt-1`}
                    >
                      {parseFloat(insurance.savings) > 0
                          ? `${insurance.savings.toString().replace(".", ",")}€ im Jahr sparen`
                          : `${Math.abs(
                              parseFloat(insurance.savings)
                          )}€ Mehrkosten pro Jahr`}
                    </p>
                )}
              </div>

            </div>
          </div>

          <div className="w-full sm:w-1/2 px-0 sm:px-4 mb-4 sm:mb-0">
            <p className="text-gray-600 mb-2">
              Beitragssatz: {(insurance.additionalContribution + 14.6).toString().replace(".", ",")}%
            </p>
            <div>
              {insurance.sections &&
                  insurance.sections.map((section, index) => (
                      <p
                          key={index}
                          className="flex items-center text-sm mb-1"
                          style={{
                            display:
                                getSectionRating(section.questions) === "" && "none",
                          }}
                      >
                    <span className="text-green-500 mr-2">
                      <RiCheckboxCircleLine size={20}/>
                    </span>
                        <span className="font-semibold mr-1">
                      {getSectionTitle(section.title)}:
                    </span>
                        <span className="text-gray-700">
                      {getSectionRating(section.questions)}
                    </span>
                      </p>
                  ))}
            </div>
          </div>

          <div className="w-full sm:w-1/4 text-left sm:text-right showDesktopPrice">
            <p className="text-2xl font-bold">{insurance.monthlyCost.toString().replace(".", ",")} €</p>
            <p className="text-xs md:text-sm text-gray-500">
              monatlich
              <span
                  data-tooltip-id={`tooltip-price-explanation-${insurance.id}`}
                data-tooltip-content={insurance.additionalContribution}
                className="cursor-help inline-block ml-1 w-4 h-4 text-xs bg-gray-300 rounded-full text-center text-gray-600">
                ?
              </span>
              <Tooltip
                id={`tooltip-price-explanation-${insurance.id}`}
                style={{ backgroundColor: "white" }}
                render={({ content }) => (
                  <ToolTipContent content={content}/>
                )}
              />
            </p>
            {!isCurrentInsurance && parseFloat(insurance.savings) !== 0 && (
              <p
                className={`text-sm ${
                  parseFloat(insurance.savings) > 0
                    ? "text-green-500"
                    : "text-red-500"
                } mt-1`}
              >
                {parseFloat(insurance.savings) > 0
                  ? `${insurance.savings.toString().replace(".", ",")}€ im Jahr sparen`
                  : `${Math.abs(
                      parseFloat(insurance.savings)
                    )}€ Mehrkosten pro Jahr`}
              </p>
            )}
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-between items-center mt-4 pt-4 border-t border-gray-200">
          <button
            className="text-sm text-gray-600 hover:text-blue-800 focus:outline-none mb-2 sm:mb-0"
            onClick={toggleOpen}
          >
            Detaillierte Leistungsübersicht {isOpen ? "▲" : "▼"}
          </button>
          <button
            onClick={nextToRegPage}
            className="bg-[#4392EC] hover:bg-[#4392EC] text-white px-10 py-2 rounded text-md hidden sm:block"
          >
            weiter
          </button>
          <div className="sm:hidden flex justify-between w-full">
            <button
              onClick={nextToRegPage}
              className="text-blue-600 font-semibold flex items-center"
            >
              <FaChevronRight className="mr-1" />
              weiter
            </button>
            <button className="text-gray-400">
              <FaEllipsisH />
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="border-t border-gray-200 p-4 sm:p-6">
          {insurance.sections &&
            insurance.sections.map((section, index) => {
              const availableQuestions = section.questions.filter(
                (q) => q.value === "yes"
              );
              if (availableQuestions.length === 0) return null;

              return (
                <div key={index} className="mb-6">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center bg-gray-100 p-3 rounded-t-lg">
                    <h3 className="font-semibold text-gray-700 w-full sm:w-1/3 mb-2 sm:mb-0">
                      {getSectionTitle(section.title)}
                    </h3>
                    <span className="text-green-500 w-full sm:w-1/3 text-left sm:text-center">
                      {getSectionRating(section.questions)}
                    </span>
                    <div className="w-full sm:w-1/3"></div>
                  </div>
                  <div className="bg-white rounded-b-lg shadow-sm">
                    {availableQuestions.map((question, qIndex) => (
                      <div
                        key={qIndex}
                        className="flex flex-col sm:flex-row justify-between items-start sm:items-center py-3 px-4 border-b border-gray-100 last:border-b-0"
                      >
                        <span className="text-gray-700 text-sm w-full sm:w-1/3 mb-2 sm:mb-0">
                          {getQuestionTypeName(question.id.split("_")[0])}
                          {console.log(question.id.split("_")[0])}
                        </span>
                        <div className="flex items-center justify-start sm:justify-center w-full sm:w-1/3 mb-2 sm:mb-0">
                          <FaCheck className="text-green-500 mr-2" />
                          <span className="text-gray-600 text-sm">
                            Wird übernommen
                          </span>
                        </div>
                        <div className="w-full sm:w-1/3 flex justify-start sm:justify-end">
                          <span
                            data-tooltip-content={getTooltipText(
                              question.id.split("_")[0]
                            )}
                            data-tooltip-id="tooltip-benefit"
                            className="cursor-help inline-block ml-1 w-4 h-4 text-xs bg-gray-300 rounded-full text-center text-gray-600"
                          >
                            ?
                          </span>
                          <Tooltip
                            id="tooltip-benefit"
                            place="top"
                            effect="solid"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default InsuranceCard;
