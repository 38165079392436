import React from "react";
import { getQuestionTypeName } from "../utils/questionTypeTranslator";
import { Tooltip } from "react-tooltip";
import { getTooltipText } from "../utils/tooltips";

const FilterCategory = React.memo(
  ({ sectionTitle, questionTypes, onFilterChange, activeFilters }) => {
    const getSectionTitle = (title) => {
      const titleMap = {
        teeth: "Zähne",
        vaccine: "Impfungen",
        sports: "Sport & Bewegung",
        medicine: "Medizin",
        cancer: "Krebsvorsorge",
        artificial: "Künstliche Befruchtung",
        service: "Service",
      };
      return titleMap[title] || title;
    };

    return (
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2 pb-2 border-b border-gray-300">
          {getSectionTitle(sectionTitle)}
        </h3>
        {questionTypes.map((type) => (
          <div
            key={type}
            className="mb-2 flex items-center justify-between whitespace-nowrap"
          >
            <label className="flex items-center space-x-2 cursor-pointer flex-shrink-0">
              <input
                type="checkbox"
                checked={activeFilters.has(type)}
                onChange={() => onFilterChange(type)}
                className="form-checkbox h-5 w-5 text-blue-600 flex-shrink-0"
              />
              <span className="text-gray-700">{getQuestionTypeName(type)}</span>
            </label>
            <div className="flex-shrink-0 ml-2">
              <span
                data-tooltip-content={getTooltipText(type)}
                data-tooltip-id="tooltip-benefit"
                className="cursor-help inline-block w-4 h-4 text-xs bg-gray-300 rounded-full text-center text-gray-600"
              >
                ?
              </span>
              <Tooltip id="tooltip-benefit" place="top" effect="solid" />
            </div>
          </div>
        ))}
      </div>
    );
  }
);

export default FilterCategory;
