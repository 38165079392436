
function ToolTipContent(props) {
  const { content } = props;
  console.log(content);
  return (
    <div className="bg-white text-left font-miloMedium z-50">
      <span className="text-gray-600 text-md font-bold">
        <strong>Gesetzlicher Grundbetrag:</strong>
      </span>
      <br />
      <span className="text-gray-600 ">
        <strong>14,6% Ihres monatlichen Gehalts</strong>
      </span>
      <br />
      <br />
      <span  className="text-gray-600">
        <strong>Zusatzbeitrag der Krankenversicherung:</strong>
      </span>
      <br />
      <span className="text-gray-600">
        <strong>{content}% Ihres monatlichen Gehalts</strong>
      </span>
      <br />
      <br />
      <span  className="text-gray-600 font-bold">
        <strong>Arbeitsgeberanteil</strong>
      </span>
      <br />
      <span className="text-gray-600">
        <strong>Falls Sie angestellt sind zahlt Ihr Arbeitgeber 50%</strong>
      </span>
    </div>
  );
}

export default ToolTipContent;
